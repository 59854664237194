import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
// //Import Scrollbar

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const ref = useRef();

  const [admin, setAdmin] = useState("admin");

  useEffect(() => {
    const accType = localStorage.getItem("acc_type");
    if (accType === "superadmin" || accType === "admin") {
      setAdmin("admin");
    } else if (accType === "business") {
      setAdmin("business");
    }

    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      {admin === "admin" ? (
        <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              {/* <li className="menu-title">{props.t("Main")} </li> */}
              <li>
                <Link to="/dashboard" className="waves-effect">
                  <i className="mdi mdi-view-dashboard"></i>
                  {/* <span className="badge rounded-pill bg-primary float-end">
                  2
                </span> */}
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-account"></i>
                  <span>{props.t("Users")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/users" className=" waves-effect">
                      <span>{props.t("User Management")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/deleteAccount" className=" waves-effect">
                      <span>{props.t("Delete Account Request")}</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-account"></i>
              <span>{props.t("Role Access")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/roles" className=" waves-effect">
                  <span>{props.t("Role Access Management")}</span>
                </Link>
              </li>

              
            </ul>
          </li>

             

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-city"></i>
                  <span>{props.t("Business")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/business" className=" waves-effect">
                      <span>{props.t("Business Management")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/venues" className=" waves-effect">
                      <span>{props.t("Venue Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/venuecheckin" className=" waves-effect">
                      <span>{props.t("Checkin Management")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/galleries" className=" waves-effect">
                      <span>{props.t("Gallery Management")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/music" className=" waves-effect">
                      <span>{props.t("Music Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/feature" className=" waves-effect">
                      <span>{props.t("Feature Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/themes" className=" waves-effect">
                      <span>{props.t("Theme Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/cuisines" className=" waves-effect">
                      <span>{props.t("Cuisine Management")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/ratings" className=" waves-effect">
                      <span>{props.t("Rating Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/follows" className=" waves-effect">
                      <span>{props.t("Follow Management")}</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-apps"></i>
                  <span>{props.t("App")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/pages" className=" waves-effect">
                      <span>{props.t("Setting Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/activityIcon" className=" waves-effect">
                      <span>{props.t("Icon Management")}</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-tooltip-edit"></i>
                  <span>{props.t("Category")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/category" className="waves-effect">
                      <span>{props.t("Category Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/banner" className="waves-effect">
                      <span>{props.t("Banner Management")}</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-cash"></i>
                  <span>{props.t("Subscription")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/package" className="waves-effect">
                      <span>{props.t("Package Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/promocode" className="waves-effect">
                      <span>{props.t("Promocode Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/subscribe" className="waves-effect">
                      <span>{props.t("Subscribe Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/orders" className=" waves-effect">
                      <span>{props.t("Order Management")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/customsubscribe" className=" waves-effect">
                      <span>{props.t("Custom Subscription Management")}</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-map"></i>
                  <span>{props.t("Offers")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/public-holiday" className=" waves-effect">
                      <span>{props.t("Public Holiday")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/offer" className=" waves-effect">
                      <span>{props.t("Offer Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/specialOffer" className=" waves-effect">
                      <span>{props.t("Discount")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/specialOfferclaim" className=" waves-effect">
                      <span>{props.t("Discount Claim")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/offerOrder" className=" waves-effect">
                      <span>{props.t("Offer Orders")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/offerNotification" className=" waves-effect">
                      <span>{props.t("Offer Notification")}</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/vouchers" className=" waves-effect">
                  <i className="mdi mdi-walk"></i>
                  <span>{props.t("Deal Management")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                <i className="fas fa-users"></i>
                  <span>{props.t("Promoter")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/promoter" className=" waves-effect">
                      <span>{props.t("Promotor Management")}</span>
                    </Link>
                  </li>

                  <li>
                <Link to="/conversation" className=" waves-effect">                
                  <span>{props.t("Conversation")}</span>
                </Link>
              </li>
                 
                </ul>
              </li>
             
              <li>
                <Link to="/ring" className=" waves-effect">
                  {/* <i className="mdi mdi-walk"></i> */}
                  <i className="fas fa-users"></i>
                  <span>{props.t("Ring Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/Log-Management-list" className=" waves-effect">
                  <i className="fas fa-users-cog"></i>
                  <span>{props.t("Log Management")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-cube-outline"></i>
                  <span>{props.t("Home Blocks")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/homeblock" className=" waves-effect">
                      <span>{props.t("Home Block Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/searchblock" className=" waves-effect">
                      <span>{props.t("Search Block Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/videos" className=" waves-effect">
                      <span>{props.t("Video Management")}</span>
                    </Link>
                  </li>
                  {/* <li>
                  <Link to="/deals" className=" waves-effect">
                    <span>{props.t("Deal Management")}</span>
                  </Link>
                </li> */}
                  {/* <li>
                  <Link to="/vouchers" className=" waves-effect">
                    <span>{props.t("Voucher Management")}</span>
                  </Link>
                </li> */}
                  <li>
                    <Link to="/customComponents" className=" waves-effect">
                      <span>{props.t("CustomComponent Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/dealOrder" className=" waves-effect">
                      <span>{props.t("Deal Orders")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/blocksize" className=" waves-effect">
                      <span>{props.t("Size Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/stories" className=" waves-effect">
                      <span>{props.t("Story Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/home-block/logs" className=" waves-effect">
                      <span>{props.t("Home Block Logs")}</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-ship-wheel"></i>
                  <span>{props.t("Yacht")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/yachtclub" className=" waves-effect">
                      <span>{props.t("Yacht Club")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/yacht" className=" waves-effect">
                      <span>{props.t("Yacht Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/yacht-offer-list" className=" waves-effect">
                      <span>{props.t("Yacht Offer")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/yachtgallery" className=" waves-effect">
                      <span>{props.t("Yacht Gallery")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/yachtrating" className=" waves-effect">
                      <span>{props.t("Yacht Rating")}</span>
                    </Link>
                  </li>
                  {/* <li>
                  <Link to="/Yacht-Package-Management-list" className=" waves-effect">
                    <span>{props.t("Yacht Packages")}</span>
                  </Link>
                </li> */}

                  <li>
                    <Link
                      to="/Yacht-icon-Management-list"
                      className=" waves-effect"
                    >
                      <span>{props.t("Add On Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/yachtfollow" className=" waves-effect">
                      <span>{props.t("Yacht Follow")}</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-camera-iris"></i>
                  <span>{props.t("Event Organizer")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/eventOrganizer" className=" waves-effect">
                      <span>{props.t("Organizer Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/events" className=" waves-effect">
                      <span>{props.t("Event Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/eventGallery" className=" waves-effect">
                      <span>{props.t("Gallery Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/eventRating" className=" waves-effect">
                      <span>{props.t("Rating Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/eventFollow" className=" waves-effect">
                      <span>{props.t("Follow Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/eventOrders" className=" waves-effect">
                      <span>{props.t("Event Orders")}</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/outinglist" className=" waves-effect">
                  <i className="mdi mdi-walk"></i>
                  <span>{props.t("Outing Management")}</span>
                </Link>
              </li>

              <li>
                <Link to="/bucketlist" className=" waves-effect">
                  <i className="mdi mdi-heart"></i>
                  <span>{props.t("Bucket Management")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-walk"></i>
                  <span>{props.t("Activity")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/activityProvider" className=" waves-effect">
                      <span>{props.t("Provider Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/activityType" className=" waves-effect">
                      <span>{props.t("Type Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/activityManagement" className=" waves-effect">
                      <span>{props.t("Activity Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/activityBanner" className=" waves-effect">
                      <span>{props.t("Banner Management")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/activityRating" className=" waves-effect">
                      <span>{props.t("Rating Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/activityFollow" className=" waves-effect">
                      <span>{props.t("Follow Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/activityOrder" className=" waves-effect">
                      <span>{props.t("Activity Orders")}</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-bell-ring"></i>
                  <span>{props.t("Notification")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/notification" className=" waves-effect">
                      <span>{props.t("Notification")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/notificationread" className=" waves-effect">
                      <span>{props.t("Notification Read")}</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-google-ads"></i>
                  <span>{props.t("Ads")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/AdsList" className=" waves-effect">
                      <span>{props.t("Ad Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/adLogs" className=" waves-effect">
                      <span>{props.t("Ad Logs")}</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/inAppNotification" className=" waves-effect">
                  <i className="mdi mdi-message"></i>
                  <span>{props.t("In-app Notification")}</span>
                </Link>
              </li>
              <li>
                <Link to="/contactUs" className=" waves-effect">
                  <i className="mdi mdi-account-box"></i>
                  <span>{props.t("Contact Us")}</span>
                </Link>
              </li>
            </ul>
          </div>
        </SimpleBar>
      ) : (
        <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              {/* <li className="menu-title">{props.t("Main")} </li> */}
              <li>
                <Link to="/business-account/dashboard" className="waves-effect">
                  <i className="mdi mdi-view-dashboard"></i>
                  {/* <span className="badge rounded-pill bg-primary float-end">
                  2
                </span> */}
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-city"></i>
                  <span>{props.t("Business")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/business" className=" waves-effect">
                      <span>{props.t("Business Management")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/venues" className=" waves-effect">
                      <span>{props.t("Venue Management")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/galleries" className=" waves-effect">
                      <span>{props.t("Gallery Management")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/music" className=" waves-effect">
                      <span>{props.t("Music Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/feature" className=" waves-effect">
                      <span>{props.t("Feature Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/themes" className=" waves-effect">
                      <span>{props.t("Theme Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/cuisines" className=" waves-effect">
                      <span>{props.t("Cuisine Management")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/ratings" className=" waves-effect">
                      <span>{props.t("Rating Management")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/follows" className=" waves-effect">
                      <span>{props.t("Follow Management")}</span>
                    </Link>
                  </li>

                  
                </ul>
              </li>

              {/* <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-tooltip-edit"></i>
                  <span>{props.t("Category")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/category" className=" waves-effect">
                      <span>{props.t("Category Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/banner" className=" waves-effect">
                      <span>{props.t("Banner Management")}</span>
                    </Link>
                  </li>
                </ul>
              </li> */}

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-map"></i>
                  <span>{props.t("Offers")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {/* <li>
                    <Link to="/public-holiday" className=" waves-effect">
                      <span>{props.t("Public Holiday")}</span>
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/offer" className=" waves-effect">
                      <span>{props.t("Offer Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/specialOffer" className=" waves-effect">
                      <span>{props.t("Discount")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/specialOfferclaim" className=" waves-effect">
                      <span>{props.t("Discount Claim")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/business-account/vouchers"
                      className="waves-effect"
                    >
                      <span>{props.t("Deal Management")}</span>
                    </Link>
                  </li>
                  {/* <li>
                  <Link to="/offerOrder" className=" waves-effect">
                    <span>{props.t("Offer Orders")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/offerNotification" className=" waves-effect">
                    <span>{props.t("Offer Notification")}</span>
                  </Link>
                </li> */}
                </ul>
              </li>

              <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-tooltip-edit"></i>
              <span>{props.t("Stories")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              {/* <li>
            <Link to="/public-holiday" className=" waves-effect">
              <span>{props.t("Public Holiday")}</span>
            </Link>
          </li> */}
              <li>
                <Link to="/business-account/stories" className=" waves-effect">
                  <span>{props.t("Story Management")}</span>
                </Link>
              </li>
             
            </ul>
          </li>
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-camera-iris"></i>
              <span>{props.t("Events")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
              
                  <Link to="/events" className=" waves-effect">                    
                    <span>{props.t("Event Management")}</span>
                  </Link>
                </li>
                </ul>
          </li>
              


              {/* <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="mdi mdi-view-dashboard"></i>               
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li> */}

              {/* <li>
              <Link to="/outinglist" className=" waves-effect">
                <i className="mdi mdi-walk"></i>
                <span>{props.t("Outing Management")}</span>
              </Link>
            </li> */}
            </ul>
            
          </div>
        </SimpleBar>
      )}
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
