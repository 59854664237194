import PropTypes from "prop-types";

import React from "react";

import { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useHistory } from "react-router-dom";

// actions
import { loginUser, apiError } from "../../store/actions";
// import images
import logoLogin from "../../assets/images/Logol.png";

import { postRequest } from "components/Common/Utils";
const Login = (props) => {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");

  async function login() {
    let data = { email, password };
    postRequest("user/admin/login", data).then((data) => {
      let log = data;
      console.log(log); 
     
      if (log.status) {
        toast.success(log.message);
        localStorage.setItem("authUser", JSON.stringify(log.data.admin));
        localStorage.setItem("access", "admin");
        localStorage.setItem("token", log.data.token);
        localStorage.setItem("acc_type", log.data.type);
        localStorage.setItem("stored_bussinessId", log.data?.admin?._id);
        localStorage.setItem("permissions", JSON.stringify(log.data.admin.permissions));

        if(log.data?.type === "business"){
          localStorage.setItem("stored_bussinessId", log.data?.admin?._id);
        }

        if(log.data?.type === "venue"){
          localStorage.setItem("stored_venueId", log.data?.admin?._id);
          localStorage.setItem("stored_temp_bId", log.data?.admin?.businessId);

          localStorage.setItem("acc_type", "venue");
          localStorage.setItem("permissions", JSON.stringify(null));
        } 
        


        console.log(log.data.type)
        

        if(log.data.type === "business"){         
          setTimeout(() => {
            history.push("/business-account/dashboard");
          }, 1000);

          return;
        }

        if(log.data.type === "venue"){         
          setTimeout(() => {
            history.push("/venue-account/dashboard");
          }, 1000);

          return;
        }
        
       
        setTimeout(() => {
          history.push("/dashboard");
        }, 1000);
      } else {
        toast.error(log.message);
      }
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                <img
                    src={logoLogin}
                    alt="logo"
                    style={{ marginLeft: "80px",height:"60px",marginTop:"25px" }}
                  />
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">
                      Welcome Back !
                    </h4>
                    <p className="text-muted text-center">
                      Sign in to continue to Whosin
                    </p>
                    <AvForm
                      className="form-horizontal mt-4"
                      onSubmit={(e) => handleValidSubmit(e)}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          // value="admin@themesbrand.com"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          // value="123456"
                          type="password"
                          required
                          onChange={(e) => setPass(e.target.value)}
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="mb-3 row mt-4">
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                        <div className="col-6 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="button"
                            onClick={login}
                          >
                            Log In
                          </button>
                        </div>
                        
                      </div>
                      <a href="/forgotPassword">Forgot Password</a>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
};
