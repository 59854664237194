import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
import {
    deleteConfirmationWords,
    postRequestForm,
} from "../../components/Common/Utils.js";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import FilterModal from "../../components/Modal/FilterModal.js";
import CustomAvatar from "../../components/Common/Avatar.js";
// import CategoryDragModal from "./CategoryDragModal.js";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Spinner,
    Badge,
    UncontrolledTooltip,
    Modal,
    Button,
} from "reactstrap";
import {
    postRequest,
    deleteRequest,
    putRequestForm,
    convertTimeToFormattedString,
} from "../../components/Common/Utils.js";

import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

// import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const filterObj = {
    title: "",
    subTitle: "",
    startColor: "",
    endColor: "",
    status: "",
    createdAt: "",
};

const RingManagementList = (props) => {
    const [modal_center, setmodal_center] = useState(false);
    const [resetSearch, setResetSearch] = useState(false);
    const [categorydata, setcategorydata] = useState([]);
    const [loader, setLoader] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedItems, setselectedItems] = useState([]);
    const [sort, setsort] = React.useState("order");
    const [sortOrder, setsortOrder] = React.useState("asc");
    const [showOrder, setShowOrder] = useState(false);
    const [currentFPage, setCurrentFPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [sizePerPageOrg, setSizePerPage] = useState(10);
    const [image, setImage] = React.useState("");
    const [openLightbox, setOpenLightBox] = React.useState(false);
    const [categorydataAll, setcategorydataAll] = useState([]);
    const [filter, setFilter] = useState({ ...filterObj });
    const [model, setModel] = useState(false);
    const [promoterModel, setpromoterModel] = useState(false);
    const [requestId, setrequestId] = useState("");

    useEffect(() => {
        promoterManagment();
    }, [currentPage]);

    useEffect(() => {
        promoterManagment();
    }, [sizePerPageOrg, sort, sortOrder]);

    const GetValueOnChange = async (status) => {
        const formData = new FormData();
        formData.append("requestId", requestId);
        formData.append("status", status);

        const res = await postRequestForm("promoter/ring/request/verify", formData);

        if (res.status == 1) {
            toast.success("promoter Status updated Successfully");
            promoterManagment();
            setpromoterModel(false);
        } else {
            toast.error("Something went wrong");
            setLoader(false);
        }
    };

    const closeModal = () => {
        setModel(false);
    };

    const toggleModel = () => {
        setModel(!model);
    };

    const updateFilter = (key, value) => {
        const filterObj = { ...filter };
        filterObj[key] = value;
        setFilter(filterObj);
    };

    const applyFilter = () => {
        const filterArgs = [];
        Object.keys(filter).forEach((key) => {
            if (
                filter[key] !== "" &&
                filter[key] !== null &&
                filter[key] !== undefined
            ) {
                if (key === "startColor") {
                    var filterObject = {
                        "color.startColor": filter["startColor"],
                    };
                } else if (key === "endColor") {
                    var filterObject = {
                        "color.endColor": filter["endColor"],
                    };
                } else {
                    var filterObject = {
                        [key]: filter[key]?.trim(),
                    };
                }

                if (
                    key === "title" ||
                    key === "subTitle" ||
                    key === "startColor" ||
                    key === "endColor"
                ) {
                    filterObject.type = "regex";
                } else if (key === "status") {
                    filterObject.type = "eq";
                } else if (key === "createdAt") {
                    filterObject.type = "date-eq";
                }

                filterArgs.push(filterObject);
            }
        });

        toggleModel();
        promoterManagment(filterArgs);
    };

    const resetFilter = () => {
        setFilter({ ...filterObj });
        toggleModel();
        promoterManagment();
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "pagination") {
            if (page != currentPage) {
                setCurrentPage(page);
            }
        }
    };

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const defaultSorted = [
        {
            dataField: "_id",
            order: "asc",
        },
    ];

    function tog_center() {
        setmodal_center(!modal_center);
        removeBodyCss();
    }

    function startColorFormatter(cellContent, row, i) {
        return (
            <>
                <div style={{ backgroundColor: row?.color?.startColor }}>
                    <p style={{ color: "#fff" }}>{row?.color?.startColor}</p>
                </div>
            </>
        );
    }
    function endColorFormatter(cellContent, row, i) {
        return (
            <>
                <div style={{ backgroundColor: row?.color?.endColor }}>
                    <p style={{ color: "#fff" }}>{row?.color?.endColor}</p>
                </div>
            </>
        );
    }

    function dateFormatter(cellContent, row) {
        return <div>{convertTimeToFormattedString(row.createdAt)}</div>;
    }

    function activeFormatter(cellContent, row) {
        return (
            <>
                <>
                    <button
                        className="btn btn-deactive"
                        type="button"
                        // onClick={() => GetValueOnChange(1, row?._id)}
                        onClick={() => {
                            setpromoterModel(true), setrequestId(row?._id);
                        }}
                        disabled={row?.referredBy !== "65c0d6ad1ccb8aa07703d3aa" || row?.status !== "pending"}
                    >
                        {row?.status}
                    </button>
                </>
            </>
        );
    }

    function ImageFormatter(cellContent, row) {
        return (
            <>
                <img
                    src={row.image}
                    onClick={() => showLightBox(row.image)}
                    alt="cover img"
                    width={"50px"}
                />
            </>
        );
    }

    function NameWithImageFormatter(cellContent, row) {
        return (
            <div style={{ display: "flex" }}>
                <div style={{ marginRight: "2px" }}>
                    {row.image ? (
                        <img
                            src={row.image}
                            onClick={() => showLightBox(row.image)}
                            alt="img"
                            width={"50px"}
                            height={"50px"}
                        />
                    ) : (
                        <CustomAvatar
                            name={
                                row?.first_name == ""
                                    ? "*"
                                    : `${row?.first_name} ${row?.last_name} `
                            }
                        />
                    )}
                </div>
                <div>  {row?.first_name || ""} {row?.last_name || ""}</div>
            </div>
        );
    }

    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ 
                    ...style, 
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center", 
                    padding: '10px', 
                    // color: 'black', 
                    zIndex: 1 
                }}
                onClick={onClick}
            >
                {/* <i className="fas fa-chevron-right" style={{ color: 'black', fontSize: '24px' }}></i> */}
            </div>
        );
    };
    
    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ 
                    ...style, 
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center", 
                    padding: '10px', 
                    zIndex: 1 
                }}
                onClick={onClick}
            >
                {/* <i className="fas fa-chevron-left" style={{ color: 'black', fontSize: '24px' }}></i> */}
            </div>
        );
    };
    
    function ImagesFormatter(cellContent, row) {
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: Math.min(row.images.length, 3),
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
        };
    
        return (
            <div style={{ width: '170px' }}> {/* Adjust the width as needed */}
                <Slider {...settings}>
                    {row.images.map((image, index) => (
                        <div key={index} style={{ padding: '5px' }}>
                            <img
                                src={image}
                                onClick={() => showLightBox(image)}
                                alt="img"
                                width={"50px"}
                                height={"50px"}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        );
    }
    
    // export default ImagesFormatter;
    



    function SocialMediaHandleFormatter(cellContent, row) {
        return (
            <div style={{ textAlign: "justify" }}>
                {row.facebook ? <>
                    <i className="fab fa-facebook"></i>
                    <span> : <a href={row.facebook}>{row.facebook}</a></span>
                </> : <></>}
                {row.instagram ? <>
                    <br />
                    <i className="fab fa-instagram"></i>
                    <span> : <a href={row.instagram}>{row.instagram}</a></span>

                </> : <></>}
                {row.youtube ? <>
                    <br />
                    <i className="fab fa-youtube"></i>
                    <span> : <a href={row.youtube}>{row.youtube}</a></span>

                </> : <></>}
                {row.tiktok ? <>
                    <br />
                    <i className="fab fa-tiktok"></i>
                    <span> : <a href={row.tiktok}>{row.tiktok}</a></span>

                </> : <></>}
            </div>
        );
    }

    const showLightBox = (image) => {
        setImage(image);
        setOpenLightBox(true);
    };

    function SNoFormatter(cellContent, row, i) {
        return <>{i + 1}</>;
    }

    const components = [
        <div className="row mb-4" key="title">
            <label className="col-sm-3 col-form-label">Name</label>
            <div className="col-sm-6">
                <input
                    type="text"
                    value={filter.name}
                    onChange={(e) => updateFilter("name", e.target.value)}
                    name="title"
                    className="form-control"
                />
            </div>
        </div>,

        <div className="row mb-4" key="title">
            <label className="col-sm-3 col-form-label">Email</label>
            <div className="col-sm-6">
                <input
                    type="text"
                    value={filter.email}
                    onChange={(e) => updateFilter("email", e.target.value)}
                    name="title"
                    className="form-control"
                />
            </div>
        </div>,
        // <div className="row mb-4" key="title">
        //     <label className="col-sm-3 col-form-label">Disclaimer</label>
        //     <div className="col-sm-6">
        //         <input
        //             type="text"
        //             value={filter.disclaimer}
        //             onChange={(e) => updateFilter("disclaimer", e.target.value)}
        //             name="title"
        //             className="form-control"
        //         />
        //     </div>
        // </div>,

        <div className="row mb-4" key="title">
            <label className="col-sm-3 col-form-label">Status</label>
            <div className="col-sm-6">
                <select
                    onChange={(e) => updateFilter("status", e.target.value)}
                    className="form-select"
                    value={filter.status}
                >
                    <option value="">Select Status</option>
                    <option value="pending">Pending</option>
                    <option value="accepted">Accepted</option>
                    <option value="rejected">Rejected</option>
                </select>
            </div>
        </div>,

        <div className="row mb-4" key="title">
            <label className="col-sm-3 col-form-label">Created Date</label>
            <div className="col-sm-6">
                <input
                    type="date"
                    value={filter.createdAt}
                    onChange={(e) => updateFilter("createdAt", e.target.value)}
                    name="createdAt"
                    className="form-control"
                />
            </div>
        </div>,

        <div className="mt-5 mt-lg-4" key="submit">
            <div className="row justify-content-end">
                <div className="col-sm-9">
                    <div>
                        <button
                            type="button"
                            onClick={applyFilter}
                            className="btn btn-primary w-md"
                        >
                            Filter
                        </button>
                        <button
                            onClick={resetFilter}
                            type="button"
                            className="btn btn-danger w-md mx-4"
                        >
                            Reset
                        </button>
                    </div>
                </div>
            </div>
        </div>,
    ];

    const columns = [
        {
            text: "S.NO",
            formatter: SNoFormatter,
            sort: true,
        },

        {
            dataField: "name",
            text: "Name",
            formatter: NameWithImageFormatter,
            sort: true,
        },
        {
            dataField: "Images",
            text: "Images",
            formatter: ImagesFormatter,
            sort: true,
        },
        {
            dataField: "email",
            text: "Email",
            sort: true,
        },
        {
            formatter: (cellContent, row) => {
                return row.country_code + ' ' + row.phone;
            },
            text: "Phone",
            sort: true,
        },
        {
            dataField: "gender",
            text: "Gender",
            sort: true,
        },
        {
            dataField: "address",
            text: "Address",
            sort: true,
        },
        {
            dataField: "nationality",
            text: "Nationality",
            sort: true,
        },
        {
            dataField: "bio",
            text: "Bio",
            formatter: (cellContent, row) => {
                return (
                    <div
                        style={{
                            maxHeight: '5em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                            // cursor: 'pointer'
                        }}
                        title={row.bio}
                    >
                        {row.bio}
                    </div>
                );
            },
            sort: true,
        },
        // {
        //     dataField: "disclaimer",
        //     text: "Disclaimer",
        //     formatter: (cellContent, row) => {
        //         return (
        //             <div
        //                 style={{
        //                     maxHeight: '5em',
        //                     overflow: 'hidden',
        //                     textOverflow: 'ellipsis',
        //                     display: '-webkit-box',
        //                     WebkitLineClamp: 3,
        //                     WebkitBoxOrient: 'vertical',
        //                     // cursor: 'pointer'
        //                 }}
        //                 title={row.disclaimer}
        //             >
        //                 {row.disclaimer}
        //             </div>
        //         );
        //     },
        //     sort: true,
        // },
        {
            dataField: "facebook",
            text: "Social Media Handles",
            formatter: SocialMediaHandleFormatter
        },
        // {
        //   dataField: "startColor",
        //   text: "Start Color",
        //   formatter: startColorFormatter,
        //   sort: true,
        // },
        // {
        //   dataField: "endColor",
        //   text: "End Color",
        //   formatter: endColorFormatter,
        //   sort: true,
        // },

        {
            dataField: "promoterName",
            text: "Promoter",
            sort: false,
        },

        {
            dataField: "status",
            text: "Status",
            formatter: activeFormatter,
            sort: false,
        },
        {
            dataField: "createdAt",
            text: "Created At",
            formatter: dateFormatter,
            sort: false,
        },
        // {
        //   text: "Actions",
        //   formatter: iconFormatter,
        //   sort: false,
        // },
    ];

    const handleChange = (event) => {
        setSizePerPage(event.target.value);
    };
    const handlesorting = (event) => {
        setsort(event.target.value);
    };
    const handlesortOrder = (event) => {
        setsortOrder(event.target.value);
    };
    const handleReload = () => {
        setResetSearch(true);
        setSearchText("");
    };

    useEffect(() => {
        if (!resetSearch) return;
        if (currentPage == 1) {
            promoterManagment([], true);
            return;
        }
        setCurrentPage(1);
    }, [resetSearch]);

    function iconFormatter(cellContent, row) {
        return (
            <React.Fragment>
                <div className="d-flex">
                    <Tippy content="Detail">
                        <button type="button" className=" btn btn-primary text-light">
                            {" "}
                            <Link
                                to={{
                                    pathname: `/categorydetails/${row._id}`,
                                    state: { row },
                                }}
                            >
                                {" "}
                                <i className="fal fa-eye text-light"></i>
                            </Link>{" "}
                        </button>
                    </Tippy>
                    <Tippy content="Edit">
                        <button type="button" className=" btn btn-info  mx-2">
                            {" "}
                            <Link
                                to={{
                                    pathname: `/Updatecategorydata/${row._id}`,
                                    state: { row },
                                }}
                            >
                                {" "}
                                <i class="fal fa-pencil fs-5 text-light"></i>
                            </Link>{" "}
                        </button>
                    </Tippy>
                    <Tippy content="Delete">
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => handlerdelete(row._id)}
                        >
                            <i class="fal fa-trash  fs-5"></i>
                        </button>
                    </Tippy>
                    &nbsp;&nbsp;
                </div>
            </React.Fragment>
        );
    }

    const promoterManagment = async (filterArgs = []) => {
        setLoader(true);
        const payload = {
            limit: sizePerPageOrg,
            page: currentPage,
            sortField: sort,
            sortOrder: sortOrder,
        };

        if (filterArgs?.length) {
            payload.filterArgs = filterArgs;
        } else {
            if (searchText && resetSearch === false) {
                payload.search = searchText.trim();
            }
        }
        try {
            const response = await postRequest(`promoter/ring/request/list`, payload);
            const data = response.data;
            if (resetSearch) {
                setResetSearch(false);
            }
            setTotalPage(data.count);
            setCurrentFPage(data.page);
            setcategorydata(data.list);
        } catch (error) {
            console.error("Failed to fetch data:", error);
        } finally {
            setLoader(false);
        }
    };

    const promoterManagmentAll = async (filterArgs = []) => {
        setLoader(true);
        const payload = {
            limit: 100000,
            page: 1,
            sortField: "order",
            sortOrder: "asc",
        };

        if (filterArgs?.length) {
            payload.filterArgs = filterArgs;
        } else {
            if (searchText) {
                payload.search = searchText;
            }
        }
        try {
            const response = await postRequest(`promoter/ring/request/list`, payload);
            const data = response.data;
            setcategorydataAll(data.list);
        } catch (error) {
            console.error("Failed to fetch data:", error);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        promoterManagmentAll();
    }, []);

    const handlerdelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, proceed",
        }).then((result) => {
            if (result.isConfirmed) {
                showRandomWordDialog(id);
            }
        });
    };

    const showRandomWordDialog = (id) => {
        const randomIndex = Math.floor(
            Math.random() * deleteConfirmationWords.length
        );
        const randomWord = deleteConfirmationWords[randomIndex];

        Swal.fire({
            title: "Confirmation Required",
            text: `To confirm deletion, please enter the word: ${randomWord}`,
            icon: "warning",
            input: "text",
            inputPlaceholder: `Type '${randomWord}' to confirm`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm",
        }).then((result) => {
            if (result.isConfirmed && result.value === randomWord) {
                deleteCategory(id);
            } else if (result.isConfirmed) {
                Swal.fire(
                    "Incorrect Entry",
                    "Please enter the correct word to confirm.",
                    "error"
                );
            }
        });
    };

    const deleteCategory = async (id) => {
        const response = await deleteRequest(`venue/category/delete`, {
            categoryId: id,
        });
        if (response.status) {
            Swal.fire("Deleted!", "Deleted successfully");
            promoterManagment();
        } else {
            Swal.fire("Error!", "Something went wrong");
        }
    };

    const handlerdeleteMultiple = (ids) => {
        Swal.fire({
            title: "Are you sure delete?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                showRandomWordDialogM(ids);
            }
        });
    };

    const showRandomWordDialogM = (ids) => {
        const randomIndex = Math.floor(
            Math.random() * deleteConfirmationWords.length
        );
        const randomWord = deleteConfirmationWords[randomIndex];

        Swal.fire({
            title: "Confirmation Required",
            text: `To confirm deletion, please enter the word: ${randomWord}`,
            icon: "warning",
            input: "text",
            inputPlaceholder: `Type '${randomWord}' to confirm`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm",
        }).then((result) => {
            if (result.isConfirmed && result.value === randomWord) {
                deleteMultipleFunction(ids);
            } else if (result.isConfirmed) {
                Swal.fire(
                    "Incorrect Entry",
                    "Please enter the correct word to confirm.",
                    "error"
                );
            }
        });
    };

    const deleteMultipleFunction = async (ids) => {
        const response = await deleteRequest("venue/category/delete", { ids: ids });
        if (response.status) {
            Swal.fire("Deleted!", "Deleted successfully");
            promoterManagment();
            setselectedItems([]);
        } else {
            Swal.fire("Error!", "Something went wrong");
        }
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        style: { background: "#c8e6c9" },
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                setselectedItems([...selectedItems, row._id]);
            } else {
                setselectedItems(selectedItems.filter((x) => x !== row._id));
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            if (!isSelect) {
                setselectedItems([]);
                return;
            }

            const ids = rows.map((r) => r._id);
            setselectedItems(ids);
        },
    };

    const updateCategoryData = async (data) => {
        setcategorydataAll(data);
        setShowOrder(false);
        const response = await postRequest(`venue/category/update/order`, {
            data: data,
        });

        if (response.status == 1) {
            toast.success("Order updated successfully");
            // wait 3 seconds before reloading the page
            setTimeout(() => {
                promoterManagment();
            }, 1000);
        } else {
            toast.error("Something went wrong");
        }
    };

    const rowClasses = (row, rowIndex) => {
        return selectedItems.includes(row._id) ? "selected-row-class" : "";
    };

    return (
        <>
            <React.Fragment>
                <ToastContainer />
                <DndProvider backend={HTML5Backend}>
                    <Row>
                        <Col className="12">
                            <div className="page-title-box">
                                <h4>Ring Management</h4>

                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item active">
                                        Ring Management
                                    </li>
                                    : <li className="breadcrumb-item">Ring Request List</li>
                                </ol>
                            </div>
                        </Col>
                        <div className="col-md-2 text-end">
                            {/* <Button
                onClick={() => setShowOrder(true)}
                className="btn btn-info"
                style={{ marginRight: "10px" }}
              >
                <i className="fas fa-grip-vertical"></i> Order
              </Button> */}

                            {/* <Link to="/Createcategorydata">
                {" "}
                <Button>
                  {" "}
                  <i class="fas fa-plus"></i> Create
                </Button>
              </Link> */}
                        </div>
                        <Col className="col-12">
                            <Card>
                                {loader ? (
                                    <CardBody style={{ height: "100px" }}>
                                        <Spinner
                                            color="info"
                                            style={{
                                                height: "4rem",
                                                width: "4rem",
                                                position: "absolute",
                                                left: "50%",
                                            }}
                                        >
                                            Loading...
                                        </Spinner>
                                    </CardBody>
                                ) : (
                                    <CardBody className="table-shorting">
                                        {
                                            <PaginationProvider
                                                pagination={paginationFactory({
                                                    page: currentFPage,
                                                    sizePerPage: sizePerPageOrg,
                                                    totalSize: totalPage,
                                                    custom: true,
                                                })}
                                            >
                                                {({ paginationProps, paginationTableProps }) => (
                                                    <>
                                                        <ToolkitProvider
                                                            keyField="_id"
                                                            data={categorydata}
                                                            columns={columns}
                                                        >
                                                            {(props) => (
                                                                <Row>
                                                                    <Col className="col-lg-12 d-flex mb-3">
                                                                        <label className="mb-0 text-label">
                                                                            Page Size
                                                                        </label>

                                                                        <select
                                                                            value={sizePerPageOrg}
                                                                            onChange={handleChange}
                                                                            className="form-select"
                                                                            style={{ width: "10%" }}
                                                                        >
                                                                            <option value="10">10</option>
                                                                            <option value="20">20</option>

                                                                            <option value="50">50</option>
                                                                        </select>

                                                                        <label className="mb-0 text-label ms-3">
                                                                            Sort By:
                                                                        </label>
                                                                        <select
                                                                            value={sort}
                                                                            onChange={handlesorting}
                                                                            className="form-select"
                                                                            style={{ width: "25%" }}
                                                                        >
                                                                            <option value="name">Name</option>
                                                                            <option value="email">Email</option>
                                                                            {/* <option value="disclaimer">
                                                                                Disclaimer
                                                                            </option> */}
                                                                            <option value="createdAt">
                                                                                Created At
                                                                            </option>
                                                                        </select>
                                                                        <label className="mb-0 text-label ms-3">
                                                                            Sort Order:
                                                                        </label>
                                                                        <select
                                                                            value={sortOrder}
                                                                            onChange={handlesortOrder}
                                                                            className="form-select"
                                                                            style={{ width: "20%" }}
                                                                        >
                                                                            <option value="asc"> Ascending</option>
                                                                            <option value="desc">Descending</option>
                                                                        </select>
                                                                        <div className="filter-item">
                                                                            {selectedItems.length > 0 && (
                                                                                <button
                                                                                    className="btn btn-danger mx-2"
                                                                                    onClick={() => {
                                                                                        handlerdeleteMultiple(
                                                                                            selectedItems
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Delete Selected
                                                                                </button>
                                                                            )}
                                                                            <input
                                                                                type="search"
                                                                                placeholder="Search"
                                                                                value={searchText}
                                                                                class="form-control"
                                                                                onChange={(e) =>
                                                                                    setSearchText(e.target.value)
                                                                                }
                                                                                style={{ width: "30%" }}
                                                                            />
                                                                            <button
                                                                                onClick={() => {
                                                                                    promoterManagment();
                                                                                }}
                                                                                type="button"
                                                                                className="btn btn-primary mx-2"
                                                                            >
                                                                                Search
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                onClick={handleReload}
                                                                                className="btn btn-danger"
                                                                            >
                                                                                Reset
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                onClick={toggleModel}
                                                                                className="btn btn-info mx-2"
                                                                            >
                                                                                Filter
                                                                            </button>
                                                                        </div>
                                                                    </Col>

                                                                    <Col className="col-lg-12 text-end">
                                                                        <BootstrapTable
                                                                            onTableChange={handleTableChange}
                                                                            noDataIndication={() => (
                                                                                <div>No data available</div>
                                                                            )}
                                                                            remote={true}
                                                                            selectRow={selectRow}
                                                                            rowClasses={rowClasses}
                                                                            // classes="table-custom-striped"
                                                                            classes="table-custom-border"
                                                                            {...props.baseProps}
                                                                            {...paginationTableProps}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                        </ToolkitProvider>

                                                        <Row>
                                                            <Col className="col-lg-12 text-end">
                                                                <div className="text-md-right">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            </PaginationProvider>
                                        }
                                    </CardBody>
                                )}
                            </Card>
                        </Col>
                    </Row>
                </DndProvider>
                {openLightbox && (
                    <Lightbox
                        mainSrc={image}
                        onCloseRequest={() => setOpenLightBox(false)}
                    />
                )}
                {/* <CategoryDragModal
          show={showOrder}
          closeModal={() => setShowOrder(false)}
          data={categorydataAll}
          setData={updateCategoryData}
        /> */}
            </React.Fragment>

            <FilterModal
                show={model}
                closeModal={closeModal}
                components={components}
            />

            <Modal
                isOpen={promoterModel}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered={true}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                        Ring Verify
                    </h5>

                    <button
                        onClick={() => {
                            setpromoterModel(false);
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="d-flex align-items-center justify-content-center">
                        <button
                            type="button"
                            onClick={() => GetValueOnChange("accepted")}
                            className="btn btn-primary mx-2"
                        >
                            Accept
                        </button>
                        <button
                            type="button"
                            onClick={() => GetValueOnChange("rejected")}
                            className="btn btn-danger"
                        >
                            Reject
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default RingManagementList;
